<template >

  <div class="resp-barre-rech" style="transition: all 0.15s ease-out; display: flex;place-content: center;align-items: flex-start;flex-direction: column; overflow: hidden" 
    :style="`padding: ${state.modeSearch == 'simple' ? '10%' : '0%'}; 
             height: ${state.modeSearch == 'simple' ? 'calc(100vh - 230px)' : 'initial'}; `" >

    <div :style="`filter: ${state.modeSearch == 'complex' && !state.isAuth  ? 'opacity(0.2)' : 'opacity(1)'}; 
                  height: ${state.modeSearch == 'complex' && !state.isAuth  ? 'calc(100vh - 40px)' : 'initial'};
                  background: ${state.modeSearch == 'simple' ? 'none' : 'rgb(255 255 255 / 27%)'};
    `" style="width: 100%; padding: 10px;" >
    
      <object class="objLogo resp-logo" data="media/still/Logo_StillStock.svg" style="width: 50%; filter: drop-shadow(6px 8px 5px rgb(0 0 0 / 0.4));" :style="`display: ${state.modeSearch == 'simple' ? 'block' : 'none'}`"> </object>
      <div style="width: 100%">
        <div
          class="d-flex align-items-center position-relative my-1"
          style="width: 100%"
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" v-show="!state.autocompleteLoading" />
            <img src="media/icons/loader.gif" style="position: relative; right: 10px" v-show="state.autocompleteLoading"/>
          </span>
          <el-autocomplete
            type="text"
            class="form-control form-control-solid ps-15 search-autocomplete inline-input"
            v-model="state.search"
            style="width: 100% !important; height: 80px; border-radius: 0px;font-size: 20px;"
            :placeholder="$t('Rechercher un ou des produits en saisissant le SKU')"
            v-on:keyup.enter="searchAPi(state.search)"
            :fetch-suggestions="querySearch"
            @select="handleSelectAutoComplete"
            ref="autocomplete"
            >
              <template #default="{ item }">
                <div class="d-flex gap-5">
                  <p class="value"><b>{{ item.prod_c_manu_part_num }}</b></p>
                  <p class="link">{{ item.prod_c_description }}</p>
                </div>
              </template>
          </el-autocomplete>
        </div>
        <template v-if="state.cSearch == 1">
          <div href="#" style="float: right; position: relative; margin-top: -66px; right: 20px; cursor : default; color: #797979; font-weight: 600; font-size: 16px;" class="fs-8">
            <img src="media/icons/loader.gif" />{{$t("Recherche en cours...")}}
          </div>
        </template>
        <template v-else>
          <div v-if="state.search.length > 3" href="#" style="float: right; position: relative; margin-top: -67px; right: 20px;" class="btn btn-lg btn-primary shadow"
            @click="searchAPi(state.search)"
           >{{$t("Lancer la recherche")}}
          </div>
        </template>
        <div class="text-white">{{$t("Pour rechercher plusieurs références spécifiques, veuillez les séparer par un")}} <b>{{$t("point virgule")}}</b>, {{$t("par exemple :")}} <div class="badge badge-secondary">C9200-48P-A;C9300-48P-A</div></div>

        <el-container :style="`display: ${state.modeSearch == 'simple' ? 'none' : 'block'}`" style="margin-top: 10px; background:white; border-radius: 0px;">
          <el-container>
            <el-aside class="resp-filter" width="300px" style="background: #fff;">
              <div class="card" style="width: 100%; border-radius: 0px; box-shadow: none;background: none;" >
                <div class="card-header border-0 pt-6">
                  <div class="card-title" style="width:100%;">
                    <h2>{{$t("Filtrer la recherche")}}</h2>
                  </div>
                </div>
                <div class="card-body pt-0">
                  <hr style="margin-top: -5px;">
                  <SearchAndFilter
                    v-if="state.initialMyList && !state.loaderEnabled"
                    :listInitial="state.initialMyList"
                    :masqueSearch="true"
                    :filters="tableFilters"
                    @returnSearch="state.myList = $event"
                    @returnLoadingDatatable="state.loadingDatatable = $event"
                  ></SearchAndFilter>
                </div>
              </div>
            </el-aside>
            <el-main style="padding: 0px;  ">

              <div class="card" style=" min-height: calc(100vh - 361px); width: 100%; border-radius: 0px; box-shadow: rgb(0 0 0 / 18%) 6px 0px 36px -18px inset, rgba(0, 0, 0, 0.18) -6px 0px 36px -18px inset; height: 100%" >
                <div class="card-header border-0 pt-6">
                  <div class="card-title" style="width:100%;">
                    <h2>{{$t("Résultat de votre recherche")}} - {{ state.myList.length }} {{$t("résultat")}}{{ state.myList.length > 1 ? 's':'' }}</h2>
                  </div>
                </div>
                <div class="card-body pt-0">
                  <hr style="margin-top: -5px;">
                  <div
                    v-if="state.myList.length == 0"
                    class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
                  >
                    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                      <inline-svg src="media/icons/duotune/general/gen048.svg" />
                    </span>
                    <div class="d-flex flex-column">
                      <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                      <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
                    </div>
                  </div>
                  <Datatable
                    v-if="state.myList && state.myList.length > 0"
                    :table-data="state.myList"
                    :table-header="tableHeader"
                    :enable-items-per-page-dropdown="true"
                    :rows-per-page="25"
                    :loading="state.loaderEnabled"
                    :empty-table-text="$t('Aucune ligne à afficher.')"
                    :key="state.loadingDatatable"
                    class="table-small-td"
                    order ='dec'
                    sortLabel="qte"
                  >
                    <template v-slot:cell-ref="{ row: data }">
                      <span style="color:#000;" class="fs-6 resp-reference">{{ data.ref }}</span>
                      <br>
                      <span style="color:#959595;" class="fs-7">{{ data.desc }}</span>
                      <br>
                      <span v-if="data.qte > 0" class="display-small badge badge-success badge-circle w-15px h-15px me-1 mt-1">
                        <span class="fs-7" v-if="data.qte > 0" style="color:#000; padding: 14px;">&nbsp;{{$t("Quantité disponible :")}}&nbsp;<b class="fs-6">{{ data.qte  }}</b><i style="color:#959595;">&nbsp;{{$t("Livrable sous 48h")}}</i></span>
                      </span>
                      <span v-if="data.qte == 0" class="display-small badge badge-danger badge-circle w-15px h-15px me-1" style="float: left;"></span>
                        <span><b class="fs-7 display-small" v-if="data.qte == 0" >&nbsp;{{$t("Aucun stock")}}</b></span>
                      <span v-if="data.leadtime"  style="color:#000; " class="fs-7 display-small">{{$t("Délai usine de")}} <b class="fs-6">{{ formatDateAgo(data.leadtime) }}</b> <i style="color:#959595;">&nbsp;{{$t("Soit à partir du")}} {{ formatDate(data.leadtime) }}</i></span>
                      <!--
                        <div href="#" class="btn btn-sm box-shadow-xs b1 btn-primary m-1 display-small" style="float: left;" @click = "toggleDisplayPanier(data)">
                          Réserver
                        </div>
                      -->
                      <div style="float: left;" href="#" class="btn btn-sm btn-white box-shadow-xs btn-primary m-1 display-small" @click = "openAside(data)">
                        {{$t("Voir le détail du produit")}}
                      </div>
                    </template>
                    <template v-slot:cell-qte="{ row: data }">
                      <div v-if="state.isAuth" style="width: 245px;">
                        
                        <div style="height: 21px; margin-top: 7px"  v-if="data.qte > 0">
                          <span class="badge badge-success badge-circle w-10px h-10px me-1" style="margin-top: 2px; float: left;"></span>
                          <span class="w-70px h-15px me-5 fs-4" style="width: 45px !important; margin-top: -6px; float: left;">&nbsp;{{ data.qte }}</span>
                          <span class="badge badge-light-success me-3" style="width: 160px; float: left; margin-top: -3px;">{{$t("Neuf - Livrable sous 48h")}}</span>
                        </div>

                        <div style="height: 21px; margin-top: 7px " v-if="data.qte == 0">
                          <span class="badge badge-danger badge-circle w-10px h-10px me-1" style="margin-top: 2px; float: left;"></span>
                          <span class="w-70px h-15px me-5 fs-4" style="width: 45px !important; margin-top: -6px; float: left;color: #d3d3d3">&nbsp;0</span>
                          <span class="badge badge-light-danger me-3" style="width: 160px; float: left; margin-top: -3px;">{{$t("Aucun stock neuf")}}</span>
                        </div>

                        <div class="mt-3 " style="height: 21px; margin-bottom: 3px" v-if="data.qte_refurb > 0">
                          <!--<span class="badge badge-circle w-10px h-10px me-1" style="margin-top: 2px; float: left;color: black; font-size: 14px;">+</span>-->
                          <span class="badge badge-success badge-circle w-10px h-10px me-1" style="margin-top: 2px; float: left; background-color: #ffe99e;"></span>
                          <span class="w-70px h-15px me-5 fs-4" style="width: 45px !important; margin-top: -6px; float: left;">&nbsp;{{ data.qte_refurb }}</span>
                          <span class="badge badge-light-dark me-3" style="width: 160px; float: left; margin-top: -3px;background-color: rgb(255 233 158 / 59%);">{{$t("Reconditionnés - Voir détails")}}</span>
                        </div>

                      </div>
                    </template>
                    <template v-slot:cell-leadtime="{ row: data }">
                      <template v-if="data.leadtime">
                        <span v-if="state.isAuth" style="color:#000;" class="fs-6">{{ formatDateAgo(data.leadtime) }}</span>
                        <br>
                        <span v-if="state.isAuth" style="color:#959595;" class="fs-6">{{ formatDate(data.leadtime) }}</span>
                      </template>
                    </template>

                    <template v-slot:cell-id="{ row: data }">
                      <div style="width: 240px; float: right;">
                        <div href="#" class="btn btn-sm btn-light-primary box-shadow-xs mx-3 b1" style="background: #FFF; float: right;" @click="toggleDisplayPanier(data)">
                          {{$t("Réserver")}}
                        </div>
                        <div style="float: right;" href="#" class="btn btn-sm btn-white box-shadow-xs mx-3" @click="openAside(data)">
                          {{$t("Détails")}}
                        </div>
                      </div>
                    </template>
                  </Datatable>
                </div>
              </div>  

            </el-main>
            
          </el-container>
        </el-container>

      </div>
    </div>
    <div v-if="state.modeSearch == 'complex' &&  !state.isAuth" style="position: fixed;     width: calc(100% - 710px);    left: calc(680px);" >
      <div class="card jumbotron" style="padding: 20px; min-height: 300px">
        <h1 class="display-4" style="color: #007ab5;">{{$t("Pour continuer, vous devez vous créer un compte.")}}</h1>
        <p class="lead" style="font-size:29px;"><b>🚀 {{$t("C'est simple, rapide, gratuit et sans engagement !")}}</b></p>
        <p style="margin-left: 15px;font-size: 20px;margin-top: 20px;">{{$t("Accèder à tout notre stock produits,")}} <br>{{$t("Veillez à avoir une vue claire sur chaque disponibilité")}}<br>{{$t("Vérifier les dates de réapprovisionnement,")}} <br>...</p><br>
      </div>
    </div>
  </div>
  <el-drawer v-model="state.statusAside" size="900px" modal-class="modal-transp" :title="$t('Détail du produit')">
    <Detail :refProduit="state.refProduit" :mProduct="state.mProduct" :key="state.refProduit" />
  </el-drawer>
  <el-drawer v-model="state.displayPanier" size="900px" modal-class="modal-transp" :title="$t('Votre demande de réservation')">
    <Panier :addArticle="state.addArticle" :key="state.affPanier"/>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Panier from "@/views/still/deploy/panier.vue";
import Detail from "@/views/still/deploy/details.vue";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    Panier,
    Detail
  },
  setup() {

    const router = useRouter();

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      search : "",
      results: [],
      lastSearch : "",
      cSearch:-1,
      modeSearch : "simple",
      myList: [] as any,
      initialMyList: [],
      loadingDatatable: 0,
      searchTitle : "",
      isAuth : store.getters.isUserAuthenticated,
      displayPanier : 0,
      addArticle: {} as any,
      affPanier : 0,
      statusAside : 0,
      refProduit: "",
      mProduct: {} as any,
      autocomplete: [],
      autocompleteLoading: false,
      lastSearchAC: "",
    });

   
    const tableHeader = ref([
      { name: "Référence produit", key: "ref", sortable: true,},
      { name: "Stock Stillnetwork", key: "qte", sortable: true, className:"hide-small"},
      { name: "Délai usine", key: "leadtime", sortable: true, className:"hide-small"},
      { name: " ", key: "id", sortable: true, className:"hide-small"},
    ]);

    const tableFilters = ref([
      { name: "Constructeur", key: "prod_c_manu_name", label: "prod_c_manu_name", },
      { name: "Famille de produit", key: "prod_c_category1", label: "prod_c_category1", },
      { name: "Classe de produit", key: "prod_c_category2", label: "prod_c_category2", },
      { name: "Sous-classe de produit", key: "prod_c_category3", label: "prod_c_category3", },
    ]);

    const autocomplete = ref(null) as any;

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");

      //return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    
    /*
      watch(state, debounce(() => {
        searchAPi(state.search);
      }, 1000));
    */

    const toggleDisplayPanier = (data) => {
      state.displayPanier = 1;
      state.addArticle = data;
      state.affPanier = state.affPanier+1;
    };

    const searchAPi = async (search) => {
      if (search.length < 3) {
        return false;
      }
      state.loaderEnabled = true;
      state.lastSearch = search;
      state.autocomplete = [];
      state.cSearch = 1;
      search = search.replaceAll('/','{');
      const mProduits = await getAxios("/getProducts/" + search);

      if(!mProduits.results) mProduits.results = [];
      
      mProduits.results.sort((a, b) => {
        const qteA = a.qte;
        const qteB = b.qte;
        if (qteA > qteB) return -1;
        if (qteA < qteB) return 1;
        return 0;
      });
      

      state.results = mProduits.results;
      state.myList = mProduits.results;
      state.initialMyList = mProduits.results;
      state.cSearch = 0;
      state.loadingDatatable++;


      let strProd = '';
      mProduits.results.forEach(el => {
        strProd = strProd + '{"vendorPartNumber": "'+ el.ref +'"},';
      });

      changeModeSearch('complex');

      state.loaderEnabled = false;
      //console.log("str", strProd);

    };


    const openAside = (data) => {
      state.mProduct = data;
      state.refProduit = data.id;
      state.statusAside = 1;
    };


    onMounted(async () => {  
      //state.loaderEnabled = false;

      if( router.currentRoute.value.params.search) {
        state.search = router.currentRoute.value.params.search as any;
        searchAPi(state.search);
      }

    });


    const changeModeSearch = (mode) => {
      state.searchTitle = state.lastSearch;
      state.modeSearch = mode;
      state.cSearch= mode == 'simple' ? 0 : 2;
      if (!state.isAuth && mode == 'complex' ) affAddAccount();
      
    };

    const affAddAccount = () => {
      router.push({ name: "sign-up" });
    }
    const querySearch = async (queryString: string, cb: any) => {
      // call callback function to return suggestions
      const splitedQuery = queryString.split(";")
      queryString = splitedQuery[splitedQuery.length - 1]
      if (queryString.length >= 5 && state.lastSearchAC !== queryString) {
        // cb(state.autocomplete)
        state.autocompleteLoading = true
        const response = await getAxios("/getAllRefs/" + btoa(queryString))
        state.autocomplete = response.length > 0 ? response : [{prod_c_manu_part_num: "Aucun résultat"}]
        state.autocompleteLoading = false
      }else if(queryString.length < 5){
        state.autocomplete = []
      }
      state.lastSearchAC = queryString
      cb(state.autocomplete)
    }

    const handleSelectAutoComplete = (item) => {
      const baseSearch = autocomplete.value.inputRef.modelValue;
      let newSearch = '';
      if (baseSearch.includes(";")) {
        const everySearch = baseSearch.split(";")
        everySearch.forEach(search => {
          if (everySearch.indexOf(search) < everySearch.length-1) {
            newSearch = newSearch + search + ';'
          }
        });
      }
      state.search = newSearch + item.prod_c_manu_part_num;
      state.autocomplete = []
      state.lastSearchAC = state.search
      searchAPi(state.search)
    }

    return {
      state,
      changeModeSearch,
      tableHeader,
      tableFilters,
      formatDate,
      formatDateAgo,
      searchAPi,
      toggleDisplayPanier,
      openAside,
      querySearch,
      handleSelectAutoComplete,
      autocomplete
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>