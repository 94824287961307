
  import { defineComponent, reactive, onMounted, ref, computed } from "vue";
  import mAxiosApi from "@/api";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import * as moment from "moment";
  import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";
  
  export default defineComponent({
    name: "Panier",
    props: {
        refProduit: String,
        mProduct : Object,
    },
    components: {
      Datatable,
    },
    setup(props) {
      //moment.default.locale("fr");
      const { t } = useI18n()
      const state = reactive({
        loaderEnabled : true,
        lstDistrib: [] as any,
        detailProduct: {} as any,
        descriptionProduct: {} as any,
        descCisco: {} as any,
        tabCisco: [] as any,
        tabFinal: [] as any,
        headProduct : {} as any,
        reload:1,
        search : String as any,
        tabsSelect: '0' as any,
        refurb: {} as any,
      });


      const tableHeader = ref([
      { name: "", key: "act_n_seq", sortable: true,},
      { name: "Distributeur", key: "act_c_raison_sociale", sortable: true,},
      { name: "Quantité", key: "prod_n_quantite", sortable: true,},
      ]);


      const retImg = (rSoc) => {
        if (rSoc == 'WESTCON COMSTOR') return "/media/stock/WESTCON.jpg";
        if (rSoc == 'INGRAM') return "/media/stock/INGRAM.jpg";
        if (rSoc == 'ALSO') return "/media/stock/ALSO.jpg";
        if (rSoc == 'TD SYNNEX') return "/media/stock/TD.jpg";
      };
      
      const nb_total_quantite = computed(() => {
        let cpt = 0;
        for(let i=0; i < state.lstDistrib.length; i++){ 
          cpt += parseInt(state.lstDistrib[i].prod_n_quantite);
        }
        return cpt;
      });

      const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
      };
      const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");
      };
      
      onMounted(async () => {  

        const response = await mAxiosApi.post('/getAllProducts/ALL', {products: props.refProduit});

        state.lstDistrib = response.data.results;
        state.headProduct = props.mProduct;
        state.search = props.refProduit; 
        state.search = state.search.replaceAll('/','{');
        
        const response2 = await getAxios('/getProductDetails/' + state.search);
        state.detailProduct = response2.results;

        const response3 = await getAxios('/getRefurb/' + state.search);
        state.refurb = response3.results;

        if (state.detailProduct && state.detailProduct['image']){

          state.tabFinal.push({  headerName: 'Visuel du produit', contents: [
            {attributeName: '',attributeValue: state.detailProduct.image},
          ] as any  });


        }
        
        if (state.detailProduct && state.headProduct && state.detailProduct['ciscoFields'] && state.headProduct.prod_c_manu_name == 'CISCO'){
          state.descCisco = state.detailProduct['ciscoFields'];
          state.tabFinal.push({  headerName: t('Spécificités CISCO'), mIndex : 0, contents: [

            {attributeName: t('Sous Groupe CISCO'),attributeValue: state.descCisco.productSubGroup?state.descCisco.productSubGroup:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Nom de service'),attributeValue: state.descCisco.serviceProgramName?state.descCisco.serviceProgramName:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Catégorie'),attributeValue: state.descCisco.itemCatalogCategory?state.descCisco.itemCatalogCategory:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Indicateur de configuration'),attributeValue: state.descCisco.configurationIndicator?state.descCisco.configurationIndicator:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Entité interne'),attributeValue: state.descCisco.internalBusinessEntity?state.descCisco.internalBusinessEntity:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Type'),attributeValue: state.descCisco.itemType?state.descCisco.itemType:'<i>'+t('inconnu')+'</i>'},


          ] as any  });

        }

        if(state.detailProduct && state.detailProduct['technicalSpecifications']){
            state.descriptionProduct = state.detailProduct['technicalSpecifications'];

            state.descriptionProduct.forEach(el => {
              if (!state.tabFinal.find(item => item.headerName === el.headerName)) {
                state.tabFinal.push({ headerName: el.headerName, mIndex : 0, contents: [] as any });
                state.tabFinal.find(item => item.headerName === el.headerName)?.contents.push({
                  attributeName: el.attributeName,
                  attributeValue: el.attributeValue
                });
              } else {
                if(el.attributeName !== "Information marketing") {
                  state.tabFinal.find(item => item.headerName === el.headerName)?.contents.push({
                  attributeName: el.attributeName,
                  attributeValue: el.attributeValue
                });
                }
              }
            });
        }

        if(state.tabFinal && state.tabFinal.length > 0 ){
          let i = 0;
          state.tabFinal.forEach(element => {
            element.mIndex = i.toString();
            i++;
          });
        }

        state.loaderEnabled = false;
        


        
      });

      return {
        state,
        tableHeader,
        nb_total_quantite,
        formatDate,
        formatDateAgo,
        retImg
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  